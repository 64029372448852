.noAccess--modal {
    padding: 48px 28px;
    background-color: #F1EEEA;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 475px;
    height: auto;
    flex-shrink: 0;

    .header {
        .header__left {
            font-size: 24px;
            font-weight: 300;
            line-height: 30px;
            text-align: center;
            margin: 0px 0px 10px 0px;
        }

        .header__right {
            float: right;
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: pointer;
        }
    }

    .noAccess__header {
        font-size: 24px;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: -0.02em;
        text-align: center;

    }

    .noAccess__body {
        .noAccess--bodyText {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
        }
    }

    .noAccess__footer {
        display: flex;
        justify-content: center;
        margin-top: 16px;

        .approve--btn {
            color: var(--Text-light, #FFF);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            background: var(--Fills-brand-medium, #197A56);
            display: inline-flex;
            height: 40px;
            padding: var(--Spacing-s, 8px) var(--Spacing-m, 36px);
            justify-content: center;
            align-items: center;
            gap: var(--Spacing-s, 8px);
            cursor: pointer;
            flex-shrink: 0;
            border: 0.5px solid #197A56;
        }

    }
}