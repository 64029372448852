@import "src/mixins/mixins";

.completedcalls__wrap {
    border-radius: 8px;
    background: #FFF;
    max-height: 365px;
    overflow-y: auto;
    // padding: 16px;

    .completedcalls__headwrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 16px 0px 16px;

        .completedcalls__leftwrap {
            display: flex;
            gap: 16px;
            align-items: center;

            .completedcalls__leftheadwrap {
                display: flex;
                align-items: center;
                gap: 4px;

                .calls--head {
                    color: var(--Colors-Grays-Black, #000);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px;
                }
            }

            .completedcalls__rightheadwrap {
                display: flex;
                align-items: center;
                gap: 4px;

                .calls--budgetSpent {
                    color: var(--CoolGray-90, #21272A);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                }

                .calls--budgetCount {
                    color: var(--CoolGray-90, #21272A);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 15px;
                    /* 125% */
                    letter-spacing: -0.24px;
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;
                    background: var(--Colour-Neutral-200, #F1EEEA);
                    height: 22px;
                    width: 43px;
                }

            }
        }

        .completedcalls__rightwrap {
            display: flex;
            align-items: center;
            gap: 16px;

            .download__callWrap {
                cursor: pointer;
            }

            .completedcalls__headviewall {
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 4px;
                color: var(--Fills-brand-medium, #197A56);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                /* 142.857% */

            }
        }
    }

    .calls--table {
        border: none;
    }

    .completedcalls__header {
        .header--title {
            height: 48px;
            background: var(--Alerts-info-light, #E0F1FF);
            padding: 8px 16px;
            border: 1px solid #E0F1FF;
            border-radius: 0px !important;

            .calls--menu {
                margin-top: -4px !important;
                left: -90px;
                width: 114px;
                border-radius: 0px !important;
                overflow-y: auto;
                fill: #FFF;
                stroke-width: 1px;
                stroke: #D4D4D4;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

                .drop--divider {
                    margin: 0px;
                }

                .selected {
                    color: #197A56;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }

                .filter--item {
                    max-height: 48px;
                    line-clamp: 1;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    height: 48px;
                    align-items: center;
                    display: flex;

                    .filter--itemClear {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        color: var(--Fills-brand-medium, #197A56);
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;

                        .cross--icon {
                            width: 12px;
                            height: 12px;
                        }
                    }

                    .filter--itemtext {
                        line-clamp: 1;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                    }

                }
            }

            .calls--menuLessData {
                max-height: 100px;
            }

            .calls--menumoreData {
                max-height: 148px;
            }

            .header--text {
                overflow: hidden;
                color: var(--Text-dark, #323232);
                text-overflow: ellipsis;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
            }

            .relevant--text {
                overflow: hidden;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                color: var(--Text-dark, #323232);
                text-overflow: ellipsis;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                margin-left: 4px;
            }

            .header--div {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                // width: 157px;
                .filter--icon {
                    height: 12px;
                    width: 12px;
                }

                .header--divText {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    // width: 80%;
                    width: auto;
                    text-wrap: nowrap;
                    @include multilineellipsis(1);
                }
            }

            .icon {
                background: transparent;
                padding-left: 8px !important;
                padding-right: 0px !important;
            }
        }
    }

    /* CompletedCalls.scss */
    .completedcalls__header th,
    .completedcalls__body td {
        border-left: none !important;
        border-right: none !important;
        padding: 8px 16px;
    }

    .completedcalls__body td {
        vertical-align: middle;
        max-width: 113px;
        width: auto;
        height: 48px;
        min-height: 48px;
        max-height: 48px;
        // text-wrap: nowrap;
    }

    .completedcalls__body .table--cell:hover {
        background-color: rgba(126, 244, 115, 0.1);
    }

    .user--name {
        overflow: hidden;
        color: var(--Text-brand-medium, #197A56);
        text-overflow: ellipsis;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        width: auto;
    }

    .user--data {
        overflow: hidden;
        color: var(--Text-dark, #323232);
        text-overflow: ellipsis;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        // width: 153px;
        width: auto;
        height: 48px;
        text-wrap: nowrap;
    }

    .nocalls__wrap {
        .nocalls__headwrap {
            background-image: url("../../assets/images/no-calls.svg");
            background-position: center;
            background-repeat: no-repeat;
            height: 143px;
            padding: 24px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;

            .nocalls--head {
                color: var(--Colors-Grays-Black, #000);
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
            }

            .nocalls--subhead {
                width: 50%;
                color: var(--Colors-Grays-Black, #000);
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
            }
        }
    }

    .right--icon {
        cursor: pointer;
    }

    .noRes__wrap {
        background-image: url("../../assets/images/no-calls.svg");
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        height: 160px;

        .nores--head {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
        }

        .nores--subhead {
            color: #323232;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            width: 377px;
        }

        .reset--icon {
            margin-top: 8px;
            cursor: pointer;
        }
    }
}

.height200 {
    min-height: 200px;
}