.downloadExperts--modal {
    padding: 48px 28px;
    background: linear-gradient(180deg, #FFF 0%, #F1EEEA 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 475px;
    height: auto;
    flex-shrink: 0;

    .downloadExperts__header {
        font-size: 24px;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: -0.02em;
        text-align: center;

    }

    .downloadExperts__body {
        .downloadExperts--bodyText {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
        }

        .downloadExperts--bodySubText {
            margin-top: 16px;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            display: flex;
            gap: 8px;
            align-items: center;
            justify-content: center;
        }

        .downloadExperts--designation {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
        }
    }

    .downloadExperts__footer {
        margin-top: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        .cancel--btn {
            color: var(--Fills-brand-medium, #197A56);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            border: 1px solid var(--Fills-brand-medium, #197A56);
            width: 108px;
            height: 40px;
            cursor: pointer;
        }

        .approve--btn {
            color: var(--Text-light, #FFF);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            background: var(--Fills-brand-medium, #197A56);
            display: inline-flex;
            height: 40px;
            padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
            justify-content: center;
            align-items: center;
            gap: var(--Spacing-s, 8px);
            cursor: pointer;
            flex-shrink: 0;
            border: 0.5px solid #197A56;
        }
        .disabled--btn{
            opacity: 0.5;
            cursor: auto;
            border: none;
        }
    }
}