.expertInterviewForm { 
        &__wrap {
        display: flex;
        justify-content: space-between;
        }

        &__heading {
            color: var(--Grays-Gray700, #323232);
            margin-top: 24px;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 25px;
            /* 125% */
            letter-spacing: -0.4px;
        }
    
        &__forminnerwrap {
            label {
                margin-top: 16px;
                color: var(--CoolGray-90, #21272A);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }
    
            .res-help-text {
                color: var(--Text-medium, #696969);
                margin-top: 4px;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                width: 100%;
            }
        }
    
        &__formwrap {
            padding-left: 50px;
    
            input[type=text][disabled] {
                background-color: #f0f0f0 !important;
            }
    
            .placeholder-option {
                color: red;
            }
    
            .disabled {
                background-color: var(--Colour-Neutral-200, #F1EEEA);
                border: 0.5px solid var(--Border-light, #B1B1B1);
            }
    
            .field-error {
                border: 1px solid red;
            }
    
            .error {
                color: red;
                font-size: 14px;
            }
    
            .form-input-fields {
                width: 100%;
                display: flex;
            }
    
            input {
                padding: var(--Spacing-m, 16px);
                align-items: flex-start;
                gap: var(--Spacing-8, 8px);
            }
    
            select {
                padding: var(--Spacing-m, 12px);
                align-items: center;
                gap: 8px;
                align-self: stretch;
            }
    
            option {
                // width: Fixed (642px)px;
                height: 48px;
                top: 86px;
                left: 27px;
                gap: 0px;
                opacity: 0px;
                border: 0.5px solid var(--Border-extra-light, #D4D4D4)
            }
    
            option>li {
                height: 48px;
                top: 86px;
                left: 27px;
                gap: 0px;
                opacity: 0px;
                border: 0.5px solid var(--Border-extra-light, #D4D4D4)
            }
    
            select:focus:not(:empty) {
                outline: none !important;
                border-color: #B1B1B1 !important;
            }
    
            .form-control.is-valid,
            .was-validated .form-control:valid {
                background-image: none;
                border-color: #B1B1B1;
            }
    
            .form-control,
            .was-validated .form-control:invalid {
                // background-position: right calc(0.35em + 0.1875rem) center;
                background-position: right calc(2.35em + 0.1875rem) center;
            }
    
            .is-fieldInvalid {
                background-position: right calc(2.35em + 0.1875rem) center;
            }
    
            .other-details {
                ul {
                    span {
                        margin-left: 5px;
                    }
                }
            }
    
            .review-link {
                padding-top: 16px;
                padding-bottom: 24px;
    
                .review-link-inner-block {
                    background: var(--Colour-Grey-700, #323232);
                    padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
                    width: fit-content;
    
                    img {
                        position: static;
                        margin-right: 8px;
                        margin-bottom: 3px;
                    }
    
                    a {
                        text-decoration: none;
                        color: white;
                    }
                }
            }
        }

    &__rightsection {
        .survey-alert {
            display: flex;
            width: 100%;
            background: var(--Alerts-info-dark, #0a477d);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            padding: 8px 16px;
            align-items: center;
            gap: var(--Spacing-xs, 4px);

            img {
                margin: auto 2px;
            }

            div {
                padding: 8px;
                // max-width: 1000px;
                width: 100%;
                color: #FFF;
            }
        }

        background: var(--Colour-Neutral-200, #F1EEEA);
        width: 100%;
        height: auto;
        padding-bottom: 24px;
    }
}

// .small {
//     width: 31.5%;
//     display: inline-block;
//     margin-right: 16px;
// }

.hiddenfield {
    z-index: 4 !important;
    position: absolute;

}

.form-footer {
    width: 100%;
    display: flex;

    button {
        width: 169px;
        height: 56px;
        margin: 24px 20px 24px 50px;
    }

    .custom-loader{
        margin-top: 35px !important;
      }
}