.searchable {
    &__wrapper {
        position: relative;

        #closedField {
            height: 47px !important;
        }

        #searchField {
            height: 40px !important;
        }

        .search--icon {
            position: absolute;
            right: 10px;
            top: 15px;
        }

        .input--outer {
            display: flex;
        }

        .chargecode--loader {
            position: absolute;
            top: 12px;
            right: 32px;

            /* Remove the background from the dimmer */
            .dimmer {
                background-color: transparent !important;

                .loader:before {
                    border-color: #B1B1B1;
                    height: 24px;
                    width: 24px;
                }

                .loader:after {
                    height: 24px;
                    width: 24px;
                }
            }
        }

        input {
            width: 100%;
        }

        .is-fieldInvalid {
            background-position: right calc(2.35em + 0.1875rem) center;
        }

        img {
            position: absolute;
            border: 0px;
            right: 28px;
        }
    }
}

.searchable__wrapper {
    .chargeCode__dropdown {
        z-index: 8;
        position: absolute;
        top: 100%;
        left: 0px;
        width: 100%;
        min-height: 50px;
        overflow-y: auto;
        gap: 0px;
        border: 0.5px 0px 0px 0px;
        border: 0.5px solid var(--Border-extra-light, #D4D4D4);
        box-shadow: 0px 16px 96px -24px #00000033;
        background: var(--Fills-light, #FFFFFF);

        .chargeCode__links {
            width: 100%;
            height: 48px;
            line-height: 24px;
            padding: 14px 14px 14px 16px;
            display: flex;
            align-items: center;
            align-self: stretch;
            border-bottom: 0.5px solid var(--Border-extra-light, #D4D4D4);
            background: var(--Fills-light, #FFF);
            box-shadow: 0px 16px 96px -24px rgba(0, 0, 0, 0.2);
            cursor: pointer;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            color: #323232 !important;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .nomatch--found {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            text-align: left;
            color: rgba(10, 71, 125, 1) !important;

            .sub--text {
                color: #323232;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                color: rgba(50, 50, 50, 1);
            }
        }
    }

    .input__container {
        position: relative;
        display: inline-block;
        width: 100%;

        img {
            position: absolute;
            top: 14px;
            margin-right: 10px;
        }
    }

    .input-fields {
        padding-right: 40px;
        /* Adjust padding to make space for the chargecode--badge */
    }

    .hasValue {
        padding-left: 102px;
    }

    .chargecode--badge {
        position: absolute;
        cursor: pointer;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        height: var(--Spacing24);
        padding: 6px;
        gap: var(--Spacing4);
        border-radius: 16px;
        background: #197A56;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: var(--Text-light, rgba(255, 255, 255, 1));
        max-width: 86px;
        width: 86px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-x: auto;
    }

}

.search--button {
    right: 0px !important;
    cursor: pointer;
}

.clear--search-btn {
    background: #197A56;
    width: 50px;
    align-content: center;

    img {
        height: 20px;
        margin: auto;
    }
}