@import "src/mixins/mixins";

.requestCalls {
    &__wrap {
        width: 100%;

        .templates__wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .templates--title {
                text-align: left;
                margin: 35px auto auto auto;
                color: #000;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
                letter-spacing: -0.4px;

            }

            .templates--subtitle {
                font-size: 16px;
                font-weight: 400;
                line-height: 18px;
                text-align: center;
                width: 540px;
                margin: 12px auto auto auto;
            }

            .closedreq--icon {
                width: 250px;
                height: 271px;
                margin: 36px auto auto auto;
            }
        }

        .width-action-col {
            width: 4.9% !important;
        }

        .expand--data {
            width: 4.9% !important;
        }

        .download__callWrap {
            cursor: pointer;
            display: inline-flex;
            height: 40px;
            padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
            justify-content: center;
            align-items: center;
            gap: var(--Spacing-s, 8px);
            flex-shrink: 0;
            color: var(--Fills-brand-medium, #197A56);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        .top-filter-name {
            border-radius: 12px;
            background: var(--Fills-light, #FFF);
            padding: 4px;
            color: var(--Text-dark, #323232);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            max-width: 120px;
            margin-bottom: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        .noCursor{
            cursor: not-allowed !important;
        }

        .selected {
            display: flex;
            border-radius: 12px;
            background: var(--Fills-light, #DCF9E3);
            height: var(--Spacing-24, 24px);
            padding: var(--Spacing-none, 0px) var(--Spacing-8, 8px);
            align-items: center;
            gap: var(--Spacing-4, 4px);
            margin-right: 16px;
            cursor: pointer;
        }

        .tooltip-id {
            position: relative;
            z-index: 10000;
        }

        .status--cell {
            width: 17.9% !important;
        }

        .completed--status {
            border-radius: var(--Spacing-xs, 4px);
            background: var(--Alerts-success-light, #DCF9E3);
            // width: 80% !important;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            padding: 8px;
            height: 24px;
        }

        .requested--status {
            border-radius: var(--Spacing-xs, 4px);
            background: var(--Alerts-warning-light, #FFFBE8);
            // width: 80% !important;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            padding: 8px;
            height: 24px;
        }

        .cancelled--status {
            border-radius: var(--Spacing-xs, 4px);
            background: var(--Alerts-danger-light, #FCE1DC);
            // width: 80% !important;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            padding: 8px;
            height: 24px;
        }

        .upcoming--status {
            border-radius: var(--Spacing-xs, 4px);
            background: var(--Alerts-info-light, #E0F1FF);
            // width: 80% !important;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            padding: 8px;
            height: 24px;
        }

        .expert--name {
            width: 17.9% !important;
            overflow: hidden;
            color: var(--Text-brand-medium, #197A56);
            text-overflow: ellipsis;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

        .other-col-width {
            width: 17.95% !important;
        }

        .requestCalls__head {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 16px;

            .download__sort {
                display: flex;
                align-items: center;
                gap: 8px;

                .sort--by {
                    overflow: hidden;
                    color: var(--Text-dark, #323232);
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    position: relative;

                    img {
                        cursor: pointer;
                    }

                    .sortby__dropdown {
                        z-index: 8;
                        position: absolute;
                        top: 100%;
                        left: 0px;
                        width: 285px;
                        max-height: 261px;
                        overflow-y: auto;
                        gap: 0px;
                        border: 0.5px 0px 0px 0px;
                        border: 0.5px solid var(--Border-extra-light, #D4D4D4);
                        box-shadow: 0px 16px 96px -24px #00000033;
                        background: var(--Fills-light, #FFFFFF);
                        width: 183px;

                        .breadcrumb__links {
                            height: 40px;
                            line-height: 24px;
                            padding: 10px;
                            display: flex;
                            align-items: center;
                            align-self: stretch;
                            border-bottom: 0.5px solid var(--Border-extra-light, #D4D4D4);
                            background: var(--Fills-light, #FFF);
                            box-shadow: 0px 16px 96px -24px rgba(0, 0, 0, 0.2);
                            cursor: pointer;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            color: #323232 !important;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-transform: capitalize;
                        }
                    }

                    /* 142.857% */
                }
            }

            .download--profiles {
                color: var(--Fills-brand-medium, #197A56);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                display: flex;
                align-items: center;
                gap: 8px;
                cursor: pointer;
                line-height: 24px;

                img {
                    height: 16px;
                }
            }
        }
    }

    &__header {
        display: flex;

        span {
            display: flex;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
        }
    }
}

.requestCalls__wrap {
    .noBorderRight {
        border-right: none !important;
    }

    .noBorderLeft {
        border-left: none !important;
    }

    #scrollable-container {
        // max-height: 510px;
        height: 510px;
        overflow-y: auto;
        position: relative;
    }

    .completedcalls__header th {
        position: sticky !important;
        top: 0;
        padding: 8px 16px;
        background: var(--Alerts-info-light, #E0F1FF) !important;
        border-radius: 0px !important;
        border-left: 0px !important;
        border-top: 0px !important;
        border-bottom: 0px !important;
        z-index: 6;
    }

    .completedcalls__header-border {
        border-right: 1px solid rgba(9, 119, 215, 0.250) !important;
    }

    .completedcalls__header {
        position: sticky !important;
        top: 0;
        // border-bottom: 1px solid #ccc;
        // overflow: auto;
        width: 100%;
        // background-color: #fff;
        z-index: 5;

        .exp-header--title {
            height: 48px;
            // background: var(--Alerts-info-light, #E0F1FF);
            padding: 8px;
            // border: 1px solid #E0F1FF;
            // border-radius: 0px !important;
            // border-right: .5px solid #ccc !important;
            width: 18%;

            .filter--countwrap {
                display: flex;
                align-items: center;
                gap: 2px;
            }

            .icon {
                background: transparent;
                padding-left: 0px !important;
                padding-right: 0px !important;
                margin-right: 0px;
            }

            .filter--count {
                min-width: 30px;
                display: inline-flex;
                height: var(--Spacing-24, 24px);
                padding: var(--Spacing-none, 0px) var(--Spacing-8, 8px);
                align-items: center;
                gap: var(--Spacing-4, 4px);
                flex-shrink: 0;
                border-radius: 12px;
                background: var(--Alerts-info-dark, #0A477D);
                margin-left: 4px;
                color: var(--Text-light, #FFF);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
            }
        }

        .header--sorttitle {
            height: 48px;
            background: var(--Alerts-info-light, #E0F1FF);
            padding: 8px;
            border: 1px solid #E0F1FF;
            border-radius: 0px !important;
            width: 60px;
            text-align: center;
        }
    }

    .completedcalls__body {
        .exp--row {
            cursor: pointer;
        }
    }

    .completedcalls__body tr {
        margin-bottom: 30px;
    }

    .loading--center {
        margin: auto;
    }

    .completedcalls__body tr:first-child td {
        border-top: none;
    }

    .completedcalls__body tr td:nth-of-type(2) {
        border-left: none !important;
    }

    .completedcalls__body td {
        vertical-align: middle;
        max-width: 113px;
        width: auto;
        height: 48px;
        min-height: 48px;
        max-height: 48px;
        padding: 8px;
        border-top: none;
        word-wrap: break-word;
        border-left: 1px solid #F2F2F2 !important;
        // border: 8px solid #F2F2F2;

        .right--icon {
            cursor: pointer;
        }
    }

    .calls--table {
        border: none;
        margin: 0 !important;
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
        text-align: left;
        border-radius: unset;
    }

    .header--div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        gap: 8px;

        .dropdown--search {
            position: sticky;
            top: 0;
            background: #fff;
            padding: 8px;
            display: flex;
            align-items: center;
            opacity: 1;
            z-index: 100;
        }

        .expertName--menu {
            width: 195px !important;
            left: -186px !important;
            top: 34px;
            margin-top: -4px !important;
            max-height: 196px;
            border-radius: 0px !important;
            overflow-y: auto;
            fill: #FFF;
            stroke-width: 1px;
            stroke: #D4D4D4;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            background: var(--Fills-light, #FFF);

            .item {
                padding: 0px !important;
                background: white;
                border: none;
            }

            .dropdown--search {
                padding: 8px;
                border: none;
            }

            .drop--divider {
                margin: 0px;
            }

            .filter--item {
                max-height: 48px;
                line-clamp: 1;
                -webkit-line-clamp: 1;
                overflow: hidden;
                height: 48px;
                align-items: center;
                display: flex;
                justify-content: space-between;
                gap: 8px;

                .filter__chk {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    width: 80%;

                }

                .filter--countText {
                    color: var(--Text-dark, #323232);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                }

                .filter--itemClear {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    color: var(--Fills-brand-medium, #197A56);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;

                    .cross--icon {
                        width: 12px;
                        height: 12px;
                    }
                }

                .filter--itemtext {
                    color: var(--CoolGray-90, #21272A);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    overflow-wrap: break-word;
                    width: 80%;
                    flex: 1;
                    max-width: 80%;
                    white-space: normal;
                    word-wrap: break-word;
                }

            }

            .search--input {
                height: 30px;
                border-radius: 0px;
                fill: var(--Fills-light, #FFF);
                stroke-width: 0.5px;
                stroke: var(--Border-extra-light, #D4D4D4);
                filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.20));
                background: white;
            }
        }

        .search--input {
            border: 1px solid #ccc;
            border-radius: 4px;
            padding: 4px 24px 4px 2px;
            flex: 1;
            width: 80%;
        }

        .filter--icon {
            height: 12px;
            width: 12px;
            cursor: pointer;
        }

        .paddingnew {
            padding: 4px 43px 4px 2px;
        }

        .search--input::placeholder {
            color: var(--CoolGray-90, #21272A);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            overflow-wrap: break-word;
            @include multilineellipsis(1);
        }

        .search--icon {
            margin-left: 0px;
            height: 16px;
            position: absolute;
            float: right;
            right: 9px;
        }

        .greensearch--icon {
            height: 30px;
        }

        .disable-icon {
            cursor: not-allowed;
        }

        .header--divText {
            width: auto;
            hyphens: auto;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex: 1;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: normal;
            overflow-wrap: break-word;
            word-break: break-word;
            hyphens: auto;
        }

        .ui.floating.dropdown>.menu {

            border-radius: 0px !important;
        }

        .calls--menu {
            left: -200px !important;
            margin-top: -4px !important;
            left: -90px;
            width: 210px;
            max-height: 196px;
            border-radius: 0px !important;
            overflow-y: auto;
            fill: #FFF;
            stroke-width: 1px;
            stroke: #D4D4D4;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            border: 0.5px solid var(--Border-light, #B1B1B1);
            background: var(--Fills-light, #FFF);

            .drop--divider {
                margin: 0px;
            }

            .filter--item {
                max-height: 48px;
                line-clamp: 1;
                -webkit-line-clamp: 1;
                overflow: hidden;
                height: 48px;
                align-items: center;
                display: flex;
                justify-content: space-between;
                gap: 8px;

                .filter__chk {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    width: 80%;

                }

                .filter--countText {
                    color: var(--Text-dark, #323232);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                }

                .filter--itemClear {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    color: var(--Fills-brand-medium, #197A56);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;

                    .cross--icon {
                        width: 12px;
                        height: 12px;
                    }
                }

                .filter--itemtext {
                    color: var(--CoolGray-90, #21272A);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    overflow-wrap: break-word;
                    width: 80%;
                    flex: 1;
                    max-width: 80%;
                    white-space: normal;
                    word-wrap: break-word;
                }

            }
        }
    }

    .last--col {
        width: auto !important;
    }
}