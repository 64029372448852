.invalid-img {
    img {
        position: unset !important;
        margin-top: -3px !important;
        margin-right: -30px !important;
    }
}

.disabled-field{
    background-color: #f0f0f0;
}

.app-date-picker-wrapper {
    width: 100%;
    height: 48px;
    border: 0.5px solid var(--Border-light, #B1B1B1);
    background: var(--Fills-light, #FFF);
    display: flex;
    .react-date-picker{
    padding-left: 16px;
    }
    

    .react-date-picker__calendar{
        width: 117% !important;
        inset: 44px auto 100% -11px !important;
    }

    .react-calendar__month-view__weekdays__weekday {
        color: var(--Text-medium, #696969);
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
     }
    
    .react-calendar{
        border: 0.5px solid var(--Border-extra-light, #D4D4D4);
        box-shadow: 0px 16px 96px -24px rgba(0, 0, 0, 0.20);
        width: 100%;
    }

    img {
        position: unset !important;
        margin-right: 10px;
    }

    .app-date-picker {
        width: 100%;

        input {
            border: 0 !important;
            margin: 0px;
            padding: 0px;
            text-align: center;
            cursor: pointer;
            font-size: small !important;
            background: transparent !important;
        }

        input::placeholder {
            font-size: small;
        }

        span {
            // padding-left: 3px;
            // padding-right: 3px;
        }

        .react-date-picker__wrapper {
            border: 0;
            height: 100%;
            width: 97%;
        }

        .react-date-picker__inputGroup__divider{
            margin-left: 4px;
            margin-right: 4px;
        }

        .react-calendar__navigation__label__labelText {
            color: #197A56;
            font-variant-numeric: lining-nums tabular-nums;
            text-overflow: ellipsis;
            font-size: 16px;
        }
        .react-calendar__navigation__arrow {
            color: #197A56;
            font-size: 25px;
        }

        .react-calendar__tile--now {
            background: var(--Fills-brand-medium, #197A56);
            color: #FFF;
        }

        .react-calendar__tile--active,
        .react-calendar__tile--active:enabled:hover,
        .react-calendar__tile--active:enabled:focus {
            opacity: 0.4;
            background: var(--Colour-Green-accent, #7EF473);
            color: black;
        }

    }
}