    .card-details-modal {
        padding: 0px;
        width: 65%;
        max-width: 65%;
        overflow: auto;
        max-height: 100%;

        .header {
            background-color: #F1EEEA;
            padding: 24px;
            display: flex;
            justify-content: space-between;

            .close--details {
                width: 40px;
                height: 40px;
                cursor: pointer;
            }
        }

        .row {
            margin: 0px !important;
        }

        .footer {
            background: #F2F2F2;
            height: 80px;
            display: flex;
            align-items: center;
        }

        .body-sub-part {
            width: 25%;
            padding: 16px 16px 16px 24px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            align-items: flex-start;
            justify-content: flex-start;

            .div-sub-part-head {
                padding: 0px;
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                opacity: 1;
                /* 142.857% */
            }

            .div-sub-part-value {
                padding: 0px;
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                /* 142.857% */
                opacity: 1;
            }
        }

        .casecode-id {
            color: var(--CoolGray-90, #21272A);
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 15px;
            /* 125% */
            letter-spacing: -0.24px;
            text-transform: uppercase;
        }

        .casecode-name {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            line-height: 30px;
            /* 125% */
            letter-spacing: -0.48px;
            margin-top: 16px;
        }

    }

    .card-details-modal .body-sub-part:nth-child(odd) {
        background-color: #ffffff;
        /* Example background color for odd columns */
    }

    // .card-details-modal .body-sub-part:nth-child(even) {
    //     background: rgba(242, 242, 242, 0.41);
    //     /* Example background color for even columns */
    //     opacity: 0.5;
    // }

    .card-details-modal .body .row:nth-child(even) .body-sub-part {
        background: rgba(242, 242, 242, 0.41);
        /* Light background color for even rows */
        opacity: 1;
    }