@import "src/mixins/mixins";

.ProjectAnalytics__wrap {

    .analytics__searchWrap {
        padding: 24px 50px;

        .search--text {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

        .search--field {
            margin-top: 16px;
            position: relative;

            .search__box {
                position: relative;

                .search--input {
                    width: 100%;
                    height: 48px;
                    background: var(--Fills-light, #FFF);
                    padding: 14px;
                }

                .search--input::placeholder {
                    color: var(--Text-dark, #323232);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    /* 142.857% */
                    opacity: 0.4;
                }

                .search--input:focus {
                    border: 1px solid var(--Border-extra-light, #D4D4D4) !important;
                }

                .search--icon {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    height: 100%;
                    cursor: pointer;
                }

                .disable--icon {
                    cursor: not-allowed;
                    opacity: 0.4;
                }

                .cross-icon {
                    position: absolute;
                    top: 14px;
                    right: 70px;
                    cursor: pointer;
                }
            }

            .suggestion__wrap {
                max-height: 240px;
                overflow-y: auto;
                position: absolute;
                width: 100%;
                z-index: 100;
                background-color: white;
                border: 1px solid #ccc;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

                .suggestion__itemWrap {
                    padding: 16px 14px;
                    border-bottom: 1px solid #D4D4D4;
                    display: flex;
                    gap: 8px;

                    .suggestion__left {

                        .table--check {
                            margin-top: 2;
                        }

                        .table--check label {
                            padding-left: 0px;
                            margin: 0px;
                        }

                        .table--check label:before {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: var(--Spacing-8, 8px);
                            border: 1px solid rgba(177, 177, 177, 1);
                        }

                    }

                    .suggestion__right {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;

                        .right__top {
                            display: flex;
                            align-items: center;
                            gap: 8px;

                            .req--id {
                                color: #000;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 20px;
                            }

                            .req--idVal {
                                color: #000;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 20px;
                            }
                        }

                        .right__bottom {
                            color: var(--CoolGray-90, #21272A);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
    }

    .analytics__body {
        padding: 0px 50px;
        margin-top: 30px;
        height: 250px;

        .body--text {
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 15px;
            letter-spacing: -0.24px;
            text-transform: uppercase;
        }

        .body__wrap {
            margin-top: 16px;
            display: flex;
            gap: 12px;
            max-width: 100%;

            .body__item {
                position: relative;
                padding: 24px;
                background: #F1EEEA;
                box-shadow: 0px 18px 40px 0px rgba(112, 144, 176, 0.12);
                max-height: 215px;
                overflow-y: auto;
                width: 25%;
                // width: 25%;
                cursor: pointer;

                .chargecross-icon {
                    margin-bottom: 10px;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    cursor: pointer;
                }

                .charge__code {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin-top: 12px;

                    .code--val {
                        color: #323232;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }

                .code--text {
                    margin-top: 8px;
                    color: var(--CoolGray-90, #21272A);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px;
                    @include multilineellipsis(2);
                }

                .spend--text {
                    margin-top: 16px;
                    color: #323232;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                }

                .code--spend {
                    color: #323232;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 35px;
                    margin-top: 8px;
                }
            }
        }

        .body__item:hover {
            .requests__wrap {
                transition: 1s 5s ease-in-out;
                display: flex;
                flex-direction: column;
                gap: 8px;

                .request--text {
                    margin-top: 16px;
                    color: #323232;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                }

                .request--detail {
                    color: #197A56;
                }
            }
        }

        .requests__wrap {
            display: none;
        }
    }

    .hovered {
        position: absolute;
        width: 25%;
        max-height: none !important;
        z-index: 100;
    }

    .lower__body {
        background: rgba(242, 242, 242, 0.51);

        .chart-overview{
            display: flex;
        }

        .chart-block {
            width: 40%;
            height: auto;
            margin: auto;
            padding: 20px;
            background: white;

            .chart-heading1 {
                color: #696969;
                font-size: 12px;
                font-weight: 700;
                line-height: 15px;
                /* 125% */
                letter-spacing: -0.24px;
                text-transform: uppercase;
            }

            .chart-heading2 {
                color: #000;
                margin-top: 6px;
                margin-bottom: 15px;
                font-size: 24px;
                font-weight: 700;
                line-height: 30px;
                /* 125% */
                letter-spacing: -0.48px;
            }
        }

        .block-header {
            color: #000;
            font-size: 32px;
            font-style: normal;
            font-weight: 300;
            line-height: 40px;
            letter-spacing: -0.64px;
            margin-bottom: 24px;
        }

        .block-sub-heading {
            margin-top: 24px;
            margin-bottom: 16px;
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            line-height: 30px;
            letter-spacing: -0.48px;
        }

        .highlighted-text {
            color: #323232;
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            /* 125% */
            letter-spacing: -0.48px;
        }

        .overview-block {
            margin-top: 16px;
            padding: 32px 50px;
        }

        .performance__overview {

            .vendortable--text {
                margin-top: 8px;
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }

            .PerformanceTable__wrap {

                .performance--table {
                    margin-top: 16px;
                    border: none;
                    width: 100%;
                    text-align: left;
                    border-radius: unset;
                }

                .perTable__header th {
                    position: sticky !important;
                    top: 0;
                    padding: 8px 16px;
                    background: var(--Alerts-info-light, #E0F1FF) !important;
                    border-radius: 0px !important;
                    border-left: 1px solid rgba(34, 36, 38, .1) !important;
                    z-index: 6;
                    height: 48px;
                    text-align: left;
                }

                .performance__body tr:first-child td {
                    border-top: none;
                }

                .performance__body td {
                    vertical-align: middle;
                    max-width: 113px;
                    width: auto;
                    height: 48px;
                    min-height: 48px;
                    max-height: 48px;
                    padding: 8px 16px;
                    border-top: none;
                    word-wrap: break-word;
                    border-left: 1px solid #F2F2F2 !important;
                    border-bottom: 1px solid #F2F2F2 !important;
                }

                .performance__body {
                    .intSpend__wrap {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}