.schedulecall {
    &__modal {
        width: 648px;
        // height: 94%;
        height: 600px;
        overflow: hidden;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        .close-icon {
            float: inline-end;
            margin: 12px 35px 12px 12px;
        }
    }

    &__wrapper {
        .disable-section{
            background-color: #f0f0f0 !important;
        }
        .time-field {
            display: inline-block;
        }

        .expert-time-field {
            width: 50%;
        }

        .right-margin {
            padding-right: 13px;
        }

        .left-margin {
            padding-left: 8px;
        }

        .slot-selection-info {
            padding: 16px 24px 8px 24px;

            span {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
        }

        .step-label {
            margin-bottom: 8px;
            margin-top: 16px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

        .step-count {
            font-weight: 700 !important;
        }

        .slot-labels {
            margin-left: 24px;
            margin-right: 24px;
        }
    }

    &__heading {
        font-style: normal;
        padding-left: 24px;
        padding-right: 24px;
        margin-top: 24px;
        margin-bottom: 16px;

        span {
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: -0.48px;
        }

        p {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            margin-top: 8px;
        }
    }

    &__sub-heading {
        display: flex;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 8px;

        img {
            margin-right: 5px;
        }
    }

    &__common-block {
        // padding: 16px 24.5px;
        padding: 0px 24.5px 16px 24.5px;

        #duration[type="time"]::-webkit-datetime-edit-ampm-field {
            display: none;
        }

        .time-timezone {
            margin-bottom: 16px;
        }

        .meeting-invites {
            // padding: 24px;

            label {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }

            .invite-input {
                border: 0.5px solid var(--Border-light, #B1B1B1);
            }
        }
    }

    &__details {

        input[type="time"]::-webkit-calendar-picker-indicator {
            display: none;
        }

        .ui.checkbox input:checked~.box:after,
        .ui.checkbox input:checked~label:after {
            color: white;
            background-color: #197A56;
            border: none !important;
            -ms-transform: scale(0.8);
            /* IE */
            -moz-transform: scale(0.8);
            /* FF */
            -webkit-transform: scale(0.8);
            /* Safari and Chrome */
            -o-transform: scale(0.8);
            /* Opera */
            transform: scale(0.8);
        }

        .ui.checkbox label:before {
            -ms-transform: scale(0.8);
            /* IE */
            -moz-transform: scale(0.8);
            /* FF */
            -webkit-transform: scale(0.8);
            /* Safari and Chrome */
            -o-transform: scale(0.8);
            /* Opera */
            transform: scale(0.8);
        }

        .slots-available {
            padding: 0px 24px;
        }

        .time-img {
            position: absolute;
            top: 202px;
            left: 258px;
        }

        .user-slots {
            display: inline-block;
            width: 100%;
        }

        .time-error{
            display: flex;
            .blank-block{
                width: 40%;
            }
        }

        .slot-button-section {
            height: 100%;
            padding-top: 20px;
        }

        .add-slot {
            background-color: #FFF;
            border: 1px solid #197A56;
            padding: 5px;
            display: flex;
            font-size: 12px;
            color: #197A56;
            height: 28px;
            align-items: center;

            img {
                margin-right: 6px;
            }
        }

        .user-date-field{
            width: 40%;
        }

        .user-time-field {
            width: 30%;
            input[type="time"]{
                width: 100%;
            }
        }

        .time-input-field{
            display: flex;
            align-items: center;
            width: 100%;
        }

        .user-time-img {
            // position: absolute;
            left: -16%;
        }

        .expert-time-img {
            // position: absolute;
            align-items: center;
            left: -6%;
        }

        .selected-slots-header {
            display: block;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            margin-top: 16px;
        }

        .slot-card {
            width: 100%;
            display: inline-block;
            padding: 16px 0px;

            .level1 {
                display: inline-table;
            }

            .slot-selection {
                width: 32px;
                height: 100%;
                background: #E0F1FF;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                padding-top: 7px;
                padding-right: 8px;
                padding-left: 8px;
            }

            .slot-details {
                width: 100%;
                height: 100%;

                .slot-date {
                    padding: 2px;
                    align-items: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px;
                    display: flex;

                    img {
                        margin-right: 5px;
                        margin-left: 5px;
                    }
                }

                .other-details {
                    display: flex;
                    align-items: center;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                }

                .slot-day {
                    font-size: 10px;
                    color: var(--Text-medium, #696969);
                }

                .slot-data {
                    font-size: 11px;
                    color: black;
                }

                .blank-div {
                    margin-right: 5px;
                    margin-left: 5px;
                    width: 16px;
                    height: 16px;
                }

                .slot-time {
                    display: flex;
                    margin-top: 8px;
                    margin-left: 26px;

                    .one-slot-block {
                        display: flex;
                        align-items: center;
                        margin-right: 16px;
                    }

                    img {
                        margin-right: 5px;
                        margin-left: 5px;
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }

        .or-div {
            border-radius: 8px;
            background: #696969;
            width: 32px;
            height: 24px;
            flex-shrink: 0;
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            text-align: center;
            position: relative;
            z-index: 3;
            left: 24px;
        }

        .strike-div {
            position: relative;

            .or-outer-div {
                background: #F2F2F2;
                width: 100%;
                height: 13px;
                position: absolute;
                z-index: -3;
            }

            .down-div {
                top: 13px;
            }
        }

        .strike-div:after {
            position: absolute;
            left: 0;
            top: 50%;
            height: 1px;
            border-bottom: 1px #696969 dashed;
            content: "";
            width: 100%;
            display: block;
        }

        .slot-header {
            width: 100%;
            display: flex;
            align-items: center;
            height: 40px;
            background: #F2F2F2;
            padding: 10px 15px 10px 22px;
            flex-shrink: 0;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;

            span {
                width: -webkit-fill-available;
            }

            // .action-img {
            //     float: inline-end;
            // }
        }

        .slot-sub-header {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            margin-top: 12px;
            margin-bottom: 16px;
        }
    }

    &__action-container {
        width: 100%;
        border-top: 1px #D4D4D4 solid;
        align-items: center;
        display: flex;
        padding: 24px;
        // margin-top: 24px;

        button {
            margin: auto;
        }
    }
}

.caller-name {
    font-weight: 400 !important;
}

.start-time-expert-field {
    display: block;
    width: 100%;
    margin-top: 8px;
    padding-left: 25px;

    div {
        width: 100%;
        display: flex;
        align-items: center;
    }
}