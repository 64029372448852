.reqDetails__description {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
    background: #FFF;
    // min-height: 339px;
    min-height: 100px;
    width: 100%;
    overflow-y: auto;

    .desc--head {
        color: var(--Grays-Gray700, #323232);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
    }

    .show--more {
        margin-top: -8px;
        cursor: pointer;
        color: var(--Fills-brand-medium, #197A56);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        display: flex;
        align-items: center;
        gap: 4px;
    }

    .desc--text {
        margin-top: -8px;
        color: var(--Colors-Grays-Gray-700, #323232);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        word-break: break-all;
    }

    .desc--text-fullHeight {
        max-height: 120px;
        overflow-y: auto;
    }

    .desc--text-halfHeight {
        max-height: 44px;
        overflow-y: hidden;
    }

    .expertType__wrap {
        .expType--head {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
        }

        .expTypes__list {
            margin-top: 6px;
            display: flex;
            align-items: center;
            gap: 8px;

            .expType--item {
                display: flex;
                padding: 8px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 8px;
                background: var(--Colour-Neutral-200, #F1EEEA);
                color: var(--Text-dark, #323232);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }

    .offLimit__companies {
        .offLimit--head {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
        }

        .show--more {
            margin-top: 8px;
        }

        .offLimit--data {
            margin-top: 6px;
            word-break: break-all;

            .offLimit--item {
                display: inline-block;
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
        }

        .offLimit--data-halfHeight {
            max-height: 44px;
            overflow-y: hidden;
        }

        .offLimit--data-fullHeight {
            max-height: 120px;
            overflow-y: auto;
        }
    }

    .interview__window {
        .interview--head {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
        }

        .interview--data {
            margin-top: 6px;
            display: flex;
            align-items: center;
            gap: 4px;

            .interview--startDate {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }
    }
}