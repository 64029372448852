.error-screen-wrap {
    background: #F1EEEA;
    width: 100%;

    .error-screen-body {
        padding: 124px 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 80px);


        .error-body-left {
            width: 365px;
            height: 256px;
        }

        .error-body-right {
            display: flex;
            flex-direction: column;
            gap: 8px;
            // border-bottom: 1px solid #C4B5A4;
            height: 256px;

            .error-head {
                margin-top: 44px;
                color: var(--Colors-Grays-Black, #000);
                text-align: left;
                font-size: 32px;
                font-style: normal;
                font-weight: 300;
                line-height: 40px;
                letter-spacing: -0.64px;
            }

            .error-subhead {
                color: #323232;
                text-align: left;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
            }

            .error-text {
                margin-top: 16px;
                color: #323232;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                width: 421px;
            }

            .error-btns {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 15px;


                .error-refresh {
                    display: flex;
                    width: 154px;
                    height: 40px;
                    padding: var(--Spacing-none, 0px) 24px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    flex-shrink: 0;
                    background: #197A56;
                    border-color: #197A56;
                    color: var(--Grays-White, #FFF);
                    text-align: left;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 17.5px;
                }

                .error-req-raise {
                    display: flex;
                    width: 165px;
                    height: 40px;
                    padding: var(--Spacing-none, 0px) 24px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    flex-shrink: 0;
                    border-color: #197A56;
                    color: var(--Colors-Text-CTA-Secondary-Default, #197A56);
                    text-align: left;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 17.5px;
                    /* 125% */
                }
            }
        }
    }
}