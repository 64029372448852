@import "src/mixins/mixins";

.openrequests__wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .requested--img{
        padding: 2px;
        width: 24px;
        height: 24px;
    }

    .openrequests__card {
        border-radius: 16px;
        border: 1px solid #0977D7;
        background: #FFF;
        cursor: pointer;
        min-height: 213px;
        margin-bottom: 16px;

        .openrequests__titlewrap {
            // padding: 24px;
            padding: 24px 24px 16px 24px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            height: 94px;
            border-radius: 16px 16px var(--Spacing-none, 0px) var(--Spacing-none, 0px);
            background: var(--Colour-Neutral-200, #F1EEEA);

            .openrequests__cardTitle {
                display: flex;
                justify-content: space-between;

                .openrequests__cardLeft {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    .request--id {
                        color: var(--CoolGray-90, #21272A);
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 15px;
                        letter-spacing: -0.24px;
                        text-transform: uppercase;
                    }

                    .charge--code {
                        color: var(--CoolGray-90, #21272A);
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 15px;
                        letter-spacing: -0.24px;
                        text-transform: uppercase;
                    }
                }

                .reqBy__onWrap{
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    .reqby--img{
                        height: 23px;
                        width: 23px;
                        border-radius: 50%;
                    }
                .requested--date {
                    color: var(--CoolGray-90, #21272A);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }
            }
            }

            .request--title {
                color: var(--CoolGray-90, #21272A);
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px !important;
                text-transform: capitalize;
                @include multilineellipsis(1);
            }
        }

        .openrequests__cardBody {
            // padding: 24px;
            padding: 24px;
            display: flex;
            align-items: center;
            gap: 45px;

            .cardBody__team {
                width: 175px;

                .team--title {
                    color: var(--CoolGray-90, #21272A);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 15px;
                    letter-spacing: -0.24px;
                    text-transform: uppercase;
                }
            }

            .cardBody__analytics {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .analytics--title {
                    color: var(--CoolGray-90, #21272A);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 15px;
                    letter-spacing: -0.24px;
                    text-transform: uppercase;
                }

                .analytics__data {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    height: 48px;

                    .expProfile__data {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        .analytics--count {
                            padding: 8px;
                            border-radius: 4px;
                            background: #E0F1FF;
                            min-width: 29px;
                            min-height: 31.146px;
                            color: var(--CoolGray-90, #21272A);
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 15px;
                            letter-spacing: -0.24px;
                            text-transform: uppercase;
                            display: flex;
                            justify-content: center;
                        }

                        .analytics--head {
                            color: var(--CoolGray-90, #21272A);
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
                        }
                    }

                    .analytics__pending {
                        display: flex;
                        align-items: center;
                        gap: 24px;

                        .pending--icon {
                            width: 65px;
                            height: 70px;
                        }

                        .pending--data {
                            width: 600px;
                            color: #7E6011;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
    }

    .openrequests__card:hover {
        box-shadow: 0px 4px 4px 0px rgba(50, 50, 50, 0.22);
    }
}