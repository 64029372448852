.form-fields {

    &__heading {
        color: var(--Grays-Gray700, #323232);
        margin-top: 24px;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        /* 125% */
        letter-spacing: -0.4px;
    }

    &__formwrap {
        label {
            margin-top: 16px;
            color: var(--CoolGray-90, #21272A);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
        }

        .res-help-text {
            color: var(--Text-medium, #696969);
            margin-top: 4px;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            width: 100%;
        }
    }

    &__wrap {
        padding-left: 32px;


        .placeholder-option {
            color: red;
        }

        .disabled {
            background-color: #f0f0f0;
            border: 0.5px solid var(--Border-light, #B1B1B1);
        }

        .field-error {
            border: 1px solid red;
        }

        .error {
            color: red;
            font-size: 14px;
        }

        .form-input-fields {
            width: 100%;
            display: flex;
        }

        input {
            padding: var(--Spacing-m, 16px);
            align-items: flex-start;
            gap: var(--Spacing-8, 8px);
        }

        select {
            padding: var(--Spacing-m, 12px);
            align-items: center;
            gap: 8px;
            align-self: stretch;
        }

        option {
            // width: Fixed (642px)px;
            height: 48px;
            top: 86px;
            left: 27px;
            gap: 0px;
            border: 0.5px 0px 0px 0px;
            opacity: 0px;
            border: 0.5px solid var(--Border-extra-light, #D4D4D4)
        }

        option>li {
            height: 48px;
            top: 86px;
            left: 27px;
            gap: 0px;
            border: 0.5px 0px 0px 0px;
            opacity: 0px;
            border: 0.5px solid var(--Border-extra-light, #D4D4D4)
        }

        .name__field {
            position: relative;
        }

        select:focus:not(:empty) {
            outline: none !important;
            border-color: #B1B1B1 !important;
        }

        .form-control.is-valid,
        .was-validated .form-control:valid {
            background-image: none;
            border-color: #B1B1B1;
        }

        .form-control,
        .was-validated .form-control:invalid {
            // background-position: right calc(0.35em + 0.1875rem) center;
            background-position: right calc(2.35em + 0.1875rem) center;
        }

        .is-fieldInvalid {
            background-position: right calc(2.35em + 0.1875rem) center;
        }

        .upload-label {
            margin-top: 16px;
            margin-bottom: 8px;
        }

        .main-upload-field {
            z-index: 2;
            position: absolute;
            height: 18px !important;
            padding: 0px;
            width: 150px;
            opacity: 0;
        }

        .review-link {
            padding-top: 16px;
            padding-bottom: 24px;

            img {
                position: static;
                margin-right: 8px;
                margin-bottom: 3px;
            }

            a {
                text-decoration: none;
                color: #197A56;
            }
        }
    }
}

.small {
    // width: 31.5%;
    width: 24%;
    display: inline-block;
    margin-right: 16px;
    vertical-align: top;
}
.special-case {
    width: 34%;
}

.hiddenfield {
    z-index: 4 !important;
    position: absolute;

}

.sub-label {
    margin-top: 0px;
    margin-bottom: 0px;
}

.uploaded-file {
    display: flex;
    margin-top: 16px;
    width: 343px;
    padding: 16px;
    align-items: center;
    gap: 16px;
    background: #FFF;
    border: 1px solid var(--Faded-Gray-1, #E4ECF5);

    img{
        cursor: pointer;
    }

    .upload-progress{
        display: flex;
        flex-direction: row;
        align-items: center;
        div{
            width: 100%;
        }
        .ui.progress.attached{
            height: 6px;
            border-radius: 6px;
            background-color: #E4ECF5;
            }
    }

    .new-file-name-block {
        display: block;
        width: 100%;

        .name-field-title {
            display: block;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            width: 100%;
        }

        .edit-file-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: var(--Spacing-4, 4px);
        }

        input{
            width: 80%;
            border: 0.5px solid var(--Border-light, #B1B1B1);
            margin-right: 18px;
        }

        img {
            // object-fit: contain;
            width: 24px;
            height: 24px;
            padding: var(--Spacing-none, 1.5px) var(--Spacing-none, 1.5px) var(--Spacing-none, 3px) var(--Spacing-none, 3px);
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
        }
    }

    .file-details {
        width: 70%;
    }

    .file-name {
        color: var(--Dark-Gray, #324054);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        word-break: break-all;
    }

    .file-other-details {
        color: var(--Secondary-Gray, #71839B);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    .upload-error {
        color: var(--Error-Red, #F92828);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        /* 133.333% */
        letter-spacing: -0.06px;
    }
}