@import "src/mixins/mixins";

.analyticscards__wrap {
    .analyticscards__body--nodata {
        justify-content: center;
        height: 173px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.81);
        background-image: url("../../assets/images/no-calls.svg");
        background-position: center;
        background-repeat: no-repeat;

        .nocalls__data {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            padding-top: 20px;

            .nocalls--head {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
            }

            .nocalls--text {
                color: #000;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                padding: 0px 16px;
            }
        }

    }

    .analyticscards__body {
        display: flex;
        align-items: center;
        gap: 16px;

        .card__wrap {
            height: 240px;
            // max-height: 240px;
            // overflow-y: auto;
            width: 25%;

            .card--divider {
                border-bottom: 1px dashed #0977D7;
                width: 95%;
                margin: auto;
            }

            .card__head {
                display: flex;
                gap: 8px;
                height: 79px;
                border-radius: 8px;
                background: rgba(255, 255, 255, 0.81);
                padding: 16px;
                align-items: center;

                .expert-img {
                    border-radius: 50%;
                    background-color: #0977D7;
                    height: 32px;
                    width: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .expert--icon {
                        height: 16px;
                        width: 16px;
                    }
                }

                .card__head--data {
                    // margin-bottom: 16px;

                    .head--count {
                        color: var(--CoolGray-90, #21272A);
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 30px;
                        letter-spacing: -0.48px;
                    }

                    .head--name {
                        color: var(--Text-medium, #696969);
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        @include multilineellipsis(1);
                    }
                }
            }
        }

        .card__body {
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.81);
            height: 151px;
            // max-height: 240px;
            overflow-y: auto;
            padding: 16px;

            .body--head {
                color: var(--Text-medium, #696969);
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }

            .body__wrap {
                display: flex;
                flex-direction: column;
                gap: 8px;
                margin-top: 8px;

                .body--itemwrap {
                    padding: 8px;
                    display: flex;
                    justify-content: space-between;
                    height: 24px;
                    align-items: center;
                    gap: var(--Spacing-xs, 4px);
                    flex-shrink: 0;
                    border-radius: var(--Spacing-xs, 4px);
                    border: 1px solid rgba(9, 119, 215, 0.4);
                }
            }
        }
    }
}