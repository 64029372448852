@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  z-index: 999;
  /* Make sure it's above other elements */
}

.mainheader {
  &__wrap {
    // padding: 10px 96px;
    padding: 0px var(--Spacing-3xl, 50px);
    background: var(--Fills-light, #FFF);
    flex-shrink: 0;
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 0.0625rem solid $gray--400;
    z-index: 110;
  }


  &__logo {
    vertical-align: top;
    padding: 0 10px 0px 0px;
    display: flex;
    align-items: center;
    height: 100%;

    img.ui.image {
      // height: 30px;
      display: inline-block;
      vertical-align: text-top;
      margin-right: 10px;
      cursor: pointer;
    }

    &-text {

      p {
        background: linear-gradient(180deg, #0977D7 0%, #197A56 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      p {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: $gray--700;
        display: block;
        margin: 0;
      }
    }
  }

  &__midSection {
    .midSection__head {
      color: var(--Text-dark, #323232);
      font-variant-numeric: lining-nums tabular-nums;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      cursor: pointer;
    }
    
  .active--page {
    border-bottom: 2px solid #21BF61;
  }
  }

  &__rightSection {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
    position: absolute;
    float: right;
    right: 50px;
  }
}

.initiate--expbtn {
  display: flex;
  height: 48px;
  padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-s, 8px);
  color: var(--Colour-Grey-White, #FFF) !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background: var(--Colour-Grey-700, #323232) !important;
  border: 3px solid #323232;
}

.notiUser__wrap {
  display: flex;
  align-items: center;
  gap: 16px;

  .bell-img {
    width: 100%;
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    z-index: 1000;
  }

  .noti-popup {
    position: absolute;
    top: 80px;
    right: 106px;
    border: 1px solid #DFD7CD;
    background: var(--Fills-light, #FFF);
    box-shadow: 0px 24px 48px -8px rgba(0, 0, 0, 0.20);
    display: flex;
    width: 480px;
    min-height: 200px;
    padding: var(--Spacing-l, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Spacing-l, 24px);
    flex-shrink: 0;
    z-index: 1000;
    background-color: white;
  }
}

.header--img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.initial-icon {
  padding: 12px;
  width: 40px;
  height: 40px;
}

.noti-open {
  background: var(--Colour-Neutral-200, #F1EEEA) !important;
  border-radius: 0% !important;
}

.noti__imgWrap {
  position: relative;
}

.noti-count {
  position: absolute;
  z-index: 1000;
  top: 9px;
  right: 10px;
  color: var(--Text-light, #FFF);
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
  border-radius: 12px;
  border: 1px solid #E0E0E0;
  background: var(--Alerts-danger, #D82216);
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}