.callsTemplate--wrapper {
    padding: 24px;

    .completedcalls--template {
        .completedcalls--header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .completedcalls--leftHead {
                display: flex;
                align-items: center;
                gap: 16px;

                .completedcalls--location {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    color: var(--Text-medium, #696969);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }

                .completedcalls--cost {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    color: var(--Text-medium, #696969);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }
        .completedcalls--headerWrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .completedcalls--body {
            margin-top: 12px;

            .attendees--head {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
            }

            .attendees--data {
                margin-top: 8px;
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;

                .templatePage__bodywrapper {
                    display: inline-block !important;
                    max-width: 100%;
                    white-space: nowrap;
                    padding-bottom: 8px;

                    .teamMembers__body {
                        display: inline-block;
                        margin-right: 8px;
                    }
                }
            }

            .margin20 {
                margin-top: 20px !important;
            }

            .completedcalls--bodyFooter {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 12px;
                gap: 8px;

                .bodyFooter--leftSection {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    color: var(--Colour-Red-300, #F48067);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    border: 1px solid var(--Colour-Red-300, #F48067);
                    height: 48px;
                    padding: 8px;
                    cursor: pointer;
                }

                .bodyFooter--rightSection {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    color: var(--Text-light, #FFF);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    background: var(--Fills-brand-medium, #197A56);
                    height: 48px;
                    padding: 8px;
                    cursor: pointer;
                    min-width: 140px;
                    justify-content: center;
                }
            }
        }

        .reschedule--call {
            display: flex;
            align-items: center;
            gap: 8px;
            color: var(--Text-light, #FFF);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            background: var(--Fills-brand-medium, #197A56);
            height: 48px;
            padding: 8px;
            width: auto;
            float: right;
            cursor: pointer;
        }

        .reschedule--call--secondarybtn {
            display: flex;
            align-items: center;
            gap: 8px;
            color: var(--Fills-brand-medium, #197A56);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            border: 1px solid var(--Fills-brand-medium, #197A56);
            height: 48px;
            padding: 8px;
            width: auto;
            float: right;
            cursor: pointer;
        }
    }
    

    .completedcalls--rightHead {
        display: flex;
        align-items: center;
        gap: 16px;

        .completedcalls--disputecall {
            display: flex;
            align-items: center;
            gap: 8px;
            color: var(--Alerts-danger-dark, #A1150C);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            cursor: pointer;
        }

        .completedcalls--call {
            border-radius: 12px;
            background: var(--Colour-Neutral-500, #AB947E);
            padding: 8px;
            color: var(--Text-light, #FFF);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
        }
    }
}