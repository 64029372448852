.externalNetworks__wrap {
    border-radius: 8px;
    background: #FFF;
    padding: 16px;
    max-height: 470px;
    overflow-y: auto;

    .externalNetworks__headwrap {
        display: flex;
        align-items: center;
        gap: 8px;

        .externalNetworks--head {
            color: var(--Colors-Grays-Black, #000);
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
        }

        .externalNetworks--count {
            display: flex;
            width: 26px;
            height: var(--Spacing-16, 16px);
            padding: 0px 4px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            flex-shrink: 0;
            border-radius: 12px;
            background: var(--Alerts-info, #0977D7);
            color: var(--Text-light, #FFF);
            text-align: center;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            /* 160% */
        }
    }

    .externalNetworks--subhead {
        color: #696969;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-top: 8px;
    }

    .externalNetworks__body {
        padding: 16px;

        .row {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
        }

        .fadeBody {
            opacity: 0.4;
        }

        .body__main {
            max-height: 92px;
            overflow-y: auto;
            // flex: 1 0 40%;
            border-radius: 8px;
            background: #F2F2F2 !important;
            padding: 16px;
            height: 92px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            cursor: pointer;
            width: 47%;

            .request--status {
                color: rgba(161, 21, 12, 0.28);
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }
        }

        .body__wrap {

            display: flex;
            justify-content: space-between;
            gap: 1px;
            width: 100%;

            .body--item {
                width: 98px;
                .body--itemText{
                    width: 100%;
                }
            }

            .body--img {
                padding: 2px;
                width: 26px;
                height: 26px;
                border-radius: 8px;
                background: rgba(255, 255, 255, 0.48);
                display: flex;
                align-items: center;
                justify-content: center;

                .slack--icon {
                    width: 100%;
                    height: 100%;
                }
            }
        }


    }
}