.NoAccess__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin: auto;
    width: 100%;   
     background: #F1EEEA;
    height: calc(100vh - 148px);

    .noAccess__header {
        color: #000;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 300;
        line-height: 40px;
        letter-spacing: -0.64px;
    }

    .noAccess__body {
        color: #323232;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        width: 27%;
    }

    .noAccess__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        .request--btn {
            color: var(--Grays-White, #FFF);
            text-align: center;
            font-variant-numeric: lining-nums tabular-nums;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 17.5px;
            display: flex;
            height: 40px;
            padding: 0px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex-shrink: 0;
            background: #197A56;
            border: 1px solid var(--Colors-Borders-Border-Default, #197A56);
        }
        .request--btnDisabled{
            opacity: 0.5;
            cursor: not-allowed !important;
        }

        .contactus--btn {
            display: flex;
            height: var(--Sizes-Compoent-Sizes-Size-Medium, 40px);
            padding: 0px var(--Sizes-Padding-Padding-Medium, 24px);
            justify-content: center;
            align-items: center;
            gap: var(--Sizes-Spacing-Spacing-X-Small, 8px);
            border: 1px solid var(--Colors-Borders-Border-Default, #197A56);
            color: var(--Colors-Text-CTA-Secondary-Default, #197A56);
            text-align: center;
            font-variant-numeric: lining-nums tabular-nums;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 17.5px;
        }
    }
}