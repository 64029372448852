.Notifications__wrap {
    .noti--head {
        color: var(--Text-dark, #323232);
        font-variant-numeric: lining-nums tabular-nums;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: -0.48px;
    }

    .noti__body {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .noti__itemWrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
        }

        .noti__item {
            position: relative;
            padding: 18px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--Spacing-none, 32px);
            background: var(--Fills-light, #FFF);

            .pin-badge{
                position: absolute;
                left: -12px;
            }
            .noti__itemSub {
                .noti--id {
                    color: var(--Text-dark, #323232);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    /* 150% */
                }

                .noti--text {
                    margin-top: 18px;
                    color: var(--Text-dark, #323232);
                    font-variant-numeric: lining-nums tabular-nums;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    /* 142.857% */
                }

                .noti--notiTime {
                    color: var(--Text-medium, #696969);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                }
            }

            .noti__itemSub2 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 8px;
                width: 100%;
            }

            .noti--pinnedWrap {
                display: flex;
                align-items: center;
                gap: 8px;
                justify-content: flex-end;

                .noti--ok {
                    background: var(--Fills-brand-medium, #197A56);
                    color: var(--Text-light, #FFF);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    cursor: pointer;
                    padding: 8px 16px;
                }
            }
        }
        .addBorder{            
            border: 1px solid var(--Colour-Neutral-500, #AB947E);
        }
    }
}